let skywise_oauth = 'https://core.skywise.com/foundry-multipass/api/oauth2';
let client_id = '7407ignhoe32jbosinen9kt252';
let client_secret ='1jmp4i4evd3aus8biotkbtihqe9ld2dplic8srvha8i6bb1mida7';
let redirect_uri ='https://sde.dev.services.2j17.aws.airbus-v.cloud';
let api_domain ='https://94ksrtjcpe.execute-api.eu-west-1.amazonaws.com/dev';
let sas_url= 'https://m2b7wzo6uc.execute-api.eu-west-1.amazonaws.com/prod';
let domain = 'https://airbus-dev-10874-2j17.auth.eu-west-1.amazoncognito.com';
let dataset_path ='https://core.skywise.com/workspace/data-integration/dataset/preview';
let sas_env = 'PROD';
let s3_private = 'https://d2kba87yqxsshs.cloudfront.net/private';
let x_api_key = 'Pw5K1BW1M98aBYnJ9paZz7metTq72bD44VbPDhAo';
let sas_x_api_key = 'zAiSOBF0iJ1TUKZHQiAeF923DEZgVEi641wjs46w';
let oauth_client_id = '9095ec157c903fb32a93401dcb6b0b08'; 
let oauth_client_secret = '2a84d9a9a03fe9a5fc9afc3bd54cfe82';
let oauth_redirect_url ='https://sde.dev.services.2j17.aws.airbus-v.cloud/SDE/ExportDS';
export const DOMAIN = domain;
export const CLIENT_ID = client_id;
export const CLIENT_SECRET = client_secret;
export const REDIRECT_URI = redirect_uri;
export const API_DOMAIN = api_domain;
export const SAS_URL = sas_url;
export const DATASET_PATH = dataset_path;
export const SAS_ENV = sas_env;
export const S3_PRIVATE = s3_private;
export const X_API_KEY = x_api_key;
export const SAS_X_API_KEY = sas_x_api_key;
export const SKYWISE_OAUTH = skywise_oauth;
export const OAUTH_REDIRECT_URI = oauth_redirect_url;
export const OAUTH_CLIENT_ID = oauth_client_id;
export const OAUTH_CLIENT_SECRET = oauth_client_secret;
